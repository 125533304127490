<template>
  <div class="talkme_group_ai_tour">
    <div class="talkme_tour_title">
      <span><img src="../../assets/ai_tour.png">AI partners with different accents</span>
      <div class="talkme_tour_desc">Learn from the unique expressions of your AI partners with diverse cultural
        backgrounds, accents, and traits.</div>
    </div>
    <vue-seamless-scroll :data="voice_list" :class-option="classOption" class="warp">
      <ul class="ul-item">
        <li class="li-item" v-for="(item, index) in voice_list" :key="index">
          <div class="ai_tour_item_gradient" :style="randomRgb(item.bgColor)">
            <img class="ai_tour_item_header" :src="item.avatar" />
          </div>
          <div class="ai_tour_item_first_group">
            <div class="ai_tour_item_title">{{ item.title }}</div>
            <div class="ai_tour_item_language_flag">
              <img :src="item.nationalFlag" />
            </div>
          </div>
          <div class="ai_tour_item_second_group">
            <div>Language :{{ item.language }}</div>
            <div> Personality :{{ item.personality }}</div>
          </div>

        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      classOption: {
        limitMoveNum: 2,
        direction: 2
      },
      voice_list: [
        {
          "id": 1,
          "title": "Ryan",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/638b5696051b4724844441ddf03427ef.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/8ae4566180804188a9d5af55a590ff63.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/s3/voice/Ryan/default.wav",
          "language": "English",
          "personality": "Friendly",
          "bgColor": "#FFD57D"
        },
        {
          "id": 2,
          "title": "Sara",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/b0ddbc34d2d94702b554c011c18f5476.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/8ae4566180804188a9d5af55a590ff63.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/s3/voice/Sara/cheerful.wav",
          "language": "English",
          "personality": "Humorous",
          "bgColor": "#C0B6FF"
        },
        {
          "id": 3,
          "title": "雲哲",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/47b10438b73e44f2bdbc89ad36f71a9d.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/0587a8eed9124e29bec507e5d97906a1.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/9bf8d324d00e4004a72c274f400627d2.wav",
          "language": "Chinese",
          "personality": "Cheerful",
          "bgColor": "#A6C5FF"
        },
        {
          "id": 4,
          "title": "晓北",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/e9b430cf35ab418989e626b22ec3cc1b.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/0587a8eed9124e29bec507e5d97906a1.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/8dc5cd94a29a4153bd09a33e751e4459.wav",
          "language": "Chinese",
          "personality": "Sensitive",
          "bgColor": "#FECECF"
        },
        {
          "id": 5,
          "title": "Lorenzo",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/4986eec4dc29404682c668745fea7e48.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/9a6b53f337de4249851ccdc60000c884.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/11e260a980bc4c748b1ec15b52652550.wav",
          "language": "Spanish",
          "personality": "Gossipy",
          "bgColor": "#A6C5FF"
        },
        {
          "id": 6,
          "title": "Estrella",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/0a21db9c3f084d3696a2a5975a7df6c5.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/9a6b53f337de4249851ccdc60000c884.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/a611bb3662d74f9a83b34bac1330c1f5.wav",
          "language": "Spanish",
          "personality": "Ridiculous",
          "bgColor": "#C0B6FF"
        },
        {
          "id": 7,
          "title": "봉진",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/ae43baf7bda040b5aff543f9aae76f43.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/a224a1c6572d4560906bc64a666140ef.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/c340adb49a5b42a385880be0b1d4b2ea.wav",
          "language": "Korean",
          "personality": "Friendly",
          "bgColor": "#A6C5FF"
        },
        {
          "id": 8,
          "title": "선하이",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/4083aaf4f653423fa11d63d071134299.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/a224a1c6572d4560906bc64a666140ef.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/a188c781d4824cd598d6e67d8625f545.wav",
          "language": "Korean",
          "personality": "Humorous",
          "bgColor": "#FECECF"
        },
        {
          "id": 9,
          "title": "直樹",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/20ed2714248247f19691904281b9d7f9.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/2902caf99e6e484984d8122e3319e68f.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/6c4cd022324c4377be5b9ab2c159a154.wav",
          "language": "Japanese",
          "personality": "Cheerful",
          "bgColor": "#FFD57D"
        },
        {
          "id": 10,
          "title": "七海",
          "avatar": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/dc14f6879d174f36ac8a2d9f7567f156.png",
          "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/2902caf99e6e484984d8122e3319e68f.png",
          "audioUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/audio/wav/352bd7e24386459ea8e8fae53f456757.wav",
          "language": "Japanese",
          "personality": "Sensitive",
          "bgColor": "#C0B6FF"
        }
      ]

    }
  },
  methods: {
    randomRgb(bgColor) {
      return {
        background: 'linear-gradient(180deg, ' + bgColor + ' 0%, ' + bgColor + '00 100%)'
      };
    }
  },
}

</script>

<style lang="less" scoped>
.talkme_group_ai_tour {
  background: #F5FBFF;
  padding-top: 120px;
  width: 100%;
  position: relative;
}

.warp {
  max-width: 1920px;
  ;
  width: 100%;
  height: 390px;
  margin: 48px auto;
  overflow: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    &.ul-item {
      display: flex;

      .li-item {
        border-top-left-radius: 140px;
        border-top-right-radius: 140px;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        width: 280px;
        height: 390px;
        margin-right: 20px;
        background-color: white;
        color: #fff;
        text-align: center;
      }
    }
  }
}


.talkme_tour_title {
  position: relative;
  max-width: 1180px;
  display: inline-block;
}

.talkme_tour_title span {
  font: 52px LexendDeca-ExtraBold;
  letter-spacing: 1px;
  word-wrap: break-word;
}

.talkme_tour_title img {
  position: absolute;
  width: 120px;
  height: 15px;
  left: -6.5%;
  top: 50px;
}

.talkme_tour_desc {
  position: relative;
  margin-top: 24px;
  color: #4B5463;
  font: 18px LexendDeca-Regular;
}

.ai_tour_item_first_group {
  display: flex;
  justify-content: space-between;
  margin: 17px 12px 0 24px;

  .ai_tour_item_title {
    color: #111111;
    font-size: 24px;
    font-family: LexendDeca-Bold;
    word-wrap: break-word;
  }

  .ai_tour_item_language_flag {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    border: 1px #EBF1F6 solid;
    overflow: hidden;
  }

  .ai_tour_item_language_flag img {
    width: 22px;
    height: 22px;
    border-radius: 11px;
  }
}


.ai_tour_item_gradient {
  width: 256px;
  height: 256px;
  margin: 12px 12px 0 auto;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 0px;
  position: relative;
}

.ai_tour_item_gradient img {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 50%;
}

.ai_tour_item_second_group {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  color: #243556;
  font-size: 16px;
  font-family: LexendDeca-Regular;
  word-wrap: break-word;
  left: 24px;
  gap: 8px;
  margin: 12px 0 0 0;
}

.ai_tour_item_language_title {
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  color: #243556;
  font-size: 16px;
  font-family: LexendDeca-Regular;
  word-wrap: break-word;
}


.ai_tour_item_language_personality {
  left: 24px;
  top: 340px;
  position: absolute;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.ai_tour_item_language_personality span {
  color: #243556;
  font-size: 16px;
  font-family: LexendDeca-Regular;
  line-height: 16px;
  word-wrap: break-word;
}
</style>