<template>
    <div id="first-part-content">
        <div class="language_ease">
            <div class="chat-line-image"><img src="../../assets/image/getstart/top_line.png"></div>
            <div class="chat-title">Chat with AI Partners<br /> Master Languages Easily</div>
        </div>
        <div class="talkme_container">
            <div class="download-group">
                <div class="chat-group-teacher-button" v-on:mouseover="toggleTabs" @click="toggleTabs"><span>Get TalkMe
                        App Now</span>
                </div>
                <div class="hidden-group-container" @mouseleave="hideTabs">
                    <div v-if="showTabs" class="tooltip">
                        <div>
                            <img v-for="item in downloadList" :key="item.id" :src="item.thumb"
                                @click="openTargetWithUrl(item.downloadURLString)">
                        </div>
                    </div>
                </div>


            </div>
            <div class="group_use_chat">
                <div class="chat-group-teacher-0"><img :src="require('../../assets/image/getstart/tour_teacher_1@2x.png')">
                </div>
                <div class="chat-group-teacher-1"><img :src="require('../../assets/image/getstart/tour_teacher_2@2x.png')">
                </div>
                <div class="chat-group-teacher-2"><img :src="require('../../assets/image/getstart/tour_teacher_3@2x.png')">
                </div>
                <div class="chat-group-teacher-3"><img :src="require('../../assets/image/getstart/tour_teacher_5@2x.png')">
                </div>
                <div class="chat-group-teacher-4"><img :src="require('../../assets/image/getstart/tour_teacher_4@2x.png')">
                </div>
            </div>
            <div class="talkme_group_pics">
                <div class="talkme_group_imag_background"></div>
                <div class="talkme_sanp_images">
                    <div class="talkme_images">
                        <img alt="" fetchpriority="high" class="talkme-image-0" decoding="async" data-nimg="1"
                            style="color:transparent;transform: translateX(0) translateY(625px) rotate(-15.83deg) translateZ(0px)"
                            :srcset="require('../../assets/image/getstart/banner_left_top@2x.png')">
                    </div>
                    <div class="talkme_images">
                        <img alt="" fetchpriority="high" class="talkme-image-1" decoding="async" data-nimg="1"
                            style="color:transparent; z-index:2000;transform:translateX(0) translateY(630px) rotate(-9deg) translateZ(0px)"
                            :srcset="require('../../assets/image/getstart/banner_left_bottom@2x.png')">
                    </div>
                    <div class="talkme_images">
                        <img alt="" fetchpriority="high" class="talkme-image-2" decoding="async" data-nimg="1"
                            style="color:transparent;transform:translateX(0) translateY(560px) rotate(9deg) translateZ(0px);"
                            :srcset="require('../../assets/image/getstart/banner_right.png')">
                    </div>
                    <div class="talkme_images">
                        <video class="talkme_video" :src="require('../../assets/image/getstart/video.mp4')" autoplay muted
                            loop playsinline></video>
                    </div>
                </div>
            </div>
        </div>
        <div class="talkme_group_language">
            <div v-for="item in languages " :key="item.index">
                <img :src="item.siteImgUrl" />
                <div>{{ item.language }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap"

export default {
    data() {
        return {
            showTabs: false,
            hideTimer: null,
            downloadList: [
                { id: 0, thumb: require('../../assets/image/menu/top_app_store.png'), downloadURLString: "https://apps.apple.com/cn/app/id6449895215?mt=12" },
                { id: 1, thumb: require('../../assets/image/menu/top_google_store.png'), downloadURLString: "https://play.google.com/store/apps/details?id=com.talkme.ai.language" }
            ],
            customerStoriesEl: null,
            titleEl: null,
            talkMeEl: null,
            imgAnimations: [{ "start": "translateX(0) translateY(125px) rotate(-15.83deg) translateZ(0px)", "end": "translateX(-90%) translateY(-95%) rotate(-5deg) translateZ(0px)" },
            { "start": "translateX(0) translateY(0px) rotate(-9deg) translateZ(0px)", "end": "translateX(-110%) translateY(45px) rotate(-9deg) translateZ(0px)" },
            { "start": "translateX(0) translateY(205px) rotate(9deg) translateZ(0px)", "end": "translateX(110%) translateY(55px) rotate(9deg) translateZ(0px)" }],
            languages: [
                {
                    "language": "English",
                    "siteImgUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/9e3fd5704e2948798218718f803220d6.png"
                },
                {
                    "language": "Spanish",
                    "siteImgUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/967ca92792664787b91d2fda8c159d2f.png"
                },
                {
                    "language": "Chinese (Simplified)",
                    "siteImgUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/0e8441c46e664380a5957a1cad06c94c.png"
                },
                {
                    "language": "Chinese (Traditional)",
                    "siteImgUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/a5f10de1aca74447bf9b87ccc1e0258d.png"
                },
                {
                    "language": "Japanese",
                    "siteImgUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/365c34cb50184681a923d6946ae57369.png"
                },
                {
                    "language": "Korean",
                    "siteImgUrl": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/416701480e164780927a1392c212dd46.png"
                }
            ]
        }
    },
    methods: {
        toggleTabs() {
            if (!this.showTabs) {
                this.showTabs = true;
            }
        },
        hideTabs() {
            this.showTabs = false;
        },
        cancelHideTimer() {
            this.hideTabs();
        }, openTargetWithUrl(urlstring) {
            this.hideTabs();
            window.open(urlstring, 'target');
        },
        setupCustomerStoriesPin() {
            if (!this.customerStoriesEl) return
            const images = this.customerStoriesEl.querySelectorAll('img')
            images.forEach((item, index) => {
                const imageAnimation = this.imgAnimations[index];
                gsap.from(item, {
                    duration: 0.1,
                    transform: imageAnimation.start
                });
                gsap.to(item, {
                    duration: 1.5,
                    transform: imageAnimation.end,
                    stagger: 0.5
                });
            })
            gsap.fromTo([this.talkMeEl], { autoAlpha: 0, y: 100 }, { autoAlpha: 1, y: 0, duration: 1 });
        }
    },
    mounted() {
        this.titleEl = document.querySelector('.chat-group-teacher-button')
        this.talkMeEl = document.querySelector('.group_use_chat')
        this.customerStoriesEl = document.querySelector('.talkme_sanp_images')
        this.setupCustomerStoriesPin();
    }
}
</script>

<style lang="less" scoped>
@headerHeight: 120px;
@header-group-margin: auto 8px;

.tooltip {
    position: absolute;
    height: @headerHeight;
    margin-top: 85px;
    background-color: white;
    border: 2px solid #EBF1F6;
    border-radius: 24px;
    fill: rgba(255, 255, 255, 0.96);
    stroke-width: 2px;
    stroke: var(--, #EBF1F6);
    filter: drop-shadow(0px 16px 24px rgba(176, 189, 218, 0.28));
    display: flex;
    z-index: 101;

    &>div {
        cursor: pointer;
        display: inline-flex;
        vertical-align: middle;
        justify-content: space-around;
        margin: auto 10px;

        &>img {
            position: relative;
            margin: auto 10px;
            width: 213px;
            height: 62px;
        }
    }
}

.tooltip:before {
    position: absolute;
    content: "";
    top: -10px;
    left: 50%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #EBF1F6;
}

/* 白色覆盖*/
.tooltip:after {
    position: absolute;
    content: "";
    /*减少两像素*/
    top: -8px;
    left: 50%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}




#first-part-content {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .talkme_container {
        position: relative;
        background-image: url('../../assets/image/getstart/gradient_background@2x.png');
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;

        .group_use_chat {
            max-width: 1180px;
            margin: auto 360px;
            width: 100%;
            height: 220px;
            position: relative;
            display: -webkit-flex;
            /* Safari */
            -webkit-justify-content: space-between;
            /* Safari 6.1+ */
            display: flex;
            justify-content: space-between;
            z-index: 1;

            &>div>img {
                height: 48px;
                width: 51px;
            }

            .chat-group-teacher-0 {
                margin: -72px auto 0 0;

                &>img {
                    height: 48px;
                    width: 51px;
                }
            }

            .chat-group-teacher-1 {
                margin: 200px 656px 0 0;

                &>img {
                    height: 48px;
                    width: 51px;
                    display: block;
                    margin: auto;
                    object-fit: cover;
                }
            }

            .chat-group-teacher-2 {
                margin: 8px 95px 0 0;

                &>img {
                    height: 67px;
                    width: 61.5px;
                    display: block;
                    margin: auto;
                    object-fit: cover;
                }
            }

            .chat-group-teacher-3 {
                margin: 184px 0px 0 0;

                &>img {
                    height: 58px;
                    width: 63.5px;
                    display: block;
                    margin: auto;
                    object-fit: cover;
                }
            }

            .chat-group-teacher-4 {
                margin: -72px 0px 0 0;

                &>img {
                    height: 74px;
                    width: 89px;
                    display: block;
                    margin: auto;
                    object-fit: cover;
                }
            }
        }

        .download-group {
            position: absolute;
            width: 100%;
            height: 300px;
            // background-color: #516EFF;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1002;

            .hidden-group-container {
                position: absolute;
                // background-color: red;
                height: 184px;
                width: 490px;
                margin: 100px 0 0 0;
                display: flex;

            }
        }

        .chat-group-teacher-button {
            position: absolute;
            background-color: #3478F6;
            height: 68px;
            width: 277px;
            display: flex;
            justify-content: center;
            border-radius: 16px;
            pointer-events: auto;
            z-index: 1000;

            &>:first-of-type {
                font-family: LexendDeca-Bold;
                font-size: 20px;
                text-align: center;
                margin: auto 0;
                color: white;
            }
        }

        .talkme_group_pics {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 688px;
            margin: 137px auto auto auto;

            .talkme_group_imag_background {
                margin-top: 246px;
                position: sticky;
                background-image: linear-gradient(#3478F6 0%, #516EFF 100%);
                background-color: #516EFF;
                width: 1134px;
                height: 1179px;
                border-radius: 50%;
                display: inline-block;
            }

            .talkme_sanp_images {
                z-index: -10;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto auto;
                overflow: visible;

                .talkme_images {
                    position: absolute;
                    bottom: 0;
                    z-index: 0;
                    transform-origin: center center;
                    flex-shrink: 0;

                    talkme-images-1 {
                        width: 280;
                        height: 220;
                    }

                    .talkme_video {
                        border-radius: 40px;
                        border: 1.12px solid #FFF;
                        background: #FFF;
                        box-shadow: 0px 64.85333px 46.08px 0px rgba(32, 44, 71, 0.21);
                        background-color: #fff;
                        position: relative;
                        justify-content: center;
                        text-align: center;
                        z-index: 11;
                        flex-shrink: 0;
                        margin: -32px auto;
                        width: 320px;
                    }
                }

                .talkme-image-0 {
                    width: 335.5px;
                    height: 294px;
                }

                .talkme-image-1 {
                    width: 288px;
                    height: 388px;
                }

                .talkme-image-2 {
                    width: 288px;
                    height: 510px;
                }
            }

        }
    }

}

.language_ease {
    top: 120px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    color: #0C1D3E;
    text-align: center;
    margin-bottom: 144px;

    .chat-title {
        display: inline-flex;
        font-family: Poppins-Bold;
        font-size: 58px;
        line-height: 150%;
        letter-spacing: 1px;
    }

    .chat-line-image {
        position: absolute;
        width: 1180px;
        height: 417px;
        margin: 128px auto auto -464px;
        overflow: hidden;
    }
}


.talkme_group_language {
    padding: 36px 24px 32px 24px;
    gap: 20px;
    background: rgba(255, 255, 255, 0.90);
    border-radius: 24px;
    border: 0.50px #EBF1F6 solid;
    backdrop-filter: blur(60px);
    justify-content: space-around;
    align-items: center;
    display: inline-flex;
}

.talkme_group_language div {
    text-align: center;
    position: relative;
    width: 180px;
    border: 0.50px white solid;
}

.talkme_group_language div img {
    position: relative;
    height: 48px;
    width: 48px;
    align-items: center;
}

.talkme_group_language div div {
    top: 8px;
    width: 180px;
    height: 24px;
    position: relative;
    text-align: bottom;
    color: #243556;
    font-size: 16px;
    font-family: LexendDeca-Regular;
    word-wrap: break-word;
}

.talkme_group_comments {
    top: 60px;
    width: 100%;
}
</style>