<template>
  <div class="content-speaking">
    <content-practice></content-practice>
    <div class="top-con">
      <div class="left-text">
        <div class="title">Carefree conversations</div>
        <div class="desc">100% Safe and private conversations with AI partners. Leave all your concerns behind.</div>
      </div>
      <div class="right-images">
        <img class="talk-page-bg" src="../../assets/image/talk-page-bg.png" alt="">
        <div class="talk-page-wrap">
          <img class="talk-page" src="../../assets/image/speaking/talk-page.png" alt="">
          <div class="wave">
            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
        </div>
        <img class="float-man" src="../../assets/image/speaking/float-man.png" alt="">
      </div>
    </div>
    <div class="module-real-time">
      <div class="left-image-real-time">
        <div class="real-time-background-image"></div>
      </div>
      <div class="right-text-real-time">
        <div class="title">Talk about anything real-life situations</div>
        <div class="desc">Chat with your AI partner on any topic. Experience multiple real-life situations to enhance
          practical language skills.</div>
      </div>
    </div>
    <div class="card-list">
      <div class="card-item" v-for="(item, index) in cardList" :key="index">
        <img :src="item.url" alt="">
        <span class="card-title">{{ item.label }}</span>
        <div class="card-content">
          <img src="../../assets/image/speaking/symbol.png" alt="">
          <div>{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="bottom-text">—— Comment are ALL from our test community and feedback E-mails(translated from their
      original language ——</div>
  </div>
</template>
<script>
import ContentPractice from './ContentPractice.vue';

export default ({
  components: {
    ContentPractice
  },
  data() {
    return {
      cardList: [{
        label: 'Ethan Parker',
        url: require('../../assets/image/speaking/header1.png'),
        content: "I used to chat with real people on social media, but I always encountered strange ones with bad accents.TalkMe's characters have a standard and pleasant voice, which is perfect for me to practice."
      },
      {
        label: 'Olivia Mitchell',
        url: require('../../assets/image/speaking/header2.png'),
        content: "I have used other AI products to learn English before, but they were very fixed and procedural. However, I found that even if I say the same thing to TalkMe, the AI will give me different replies!"
      },
      {
        label: '박지영',
        url: require('../../assets/image/speaking/header3.png'),
        content: "AI tutosr are really nice! I can chat about anything with them, even if I make a mistake, No more worries about saying the wrong thing and feeling nervous!"
      }]
    }
  }
}) 
</script>
<style lang="less" scoped>
.wave {
  left: 150px;
  position: absolute;
  width: 320px;
  height: 45px;
  bottom: 56px;
}

.wave div[class^="pulse"] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  box-shadow: 0 0 12px #3478F6;
  border-radius: 50%;
  animation: pulse 3s linear infinite;
}

.wave .pulse1 {
  animation-delay: 0s !important;
}

.wave .pulse2 {
  animation-delay: 1s !important;
}

.wave .pulse3 {
  animation-delay: 2s !important;
}

@keyframes pulse {
  0% {
    width: 56px;
    height: 56px;
  }

  20% {
    width: 80px;
    height: 80px;
    opacity: 0.25;
  }

  50% {
    width: 120px;
    height: 120px;
    opacity: 0.5;
  }

  100% {
    width: 180px;
    height: 180px;
    opacity: 0;
  }
}

.content-speaking {
  max-width: 1920px;
  background: url('../../assets/image/speaking/content-speaking-bg@2x.png') repeat-y;
  background-size: 100% 100%;
  margin: 60px 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .top-con {
    display: flex;
    justify-content: center;
    position: relative;

    .left-text {
      padding-top: 160px;
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 640px;

      img {
        width: 120px;
      }
    }

    .right-images {
      position: relative;
      width: 520px;
      margin: 0 0 60px 0;

      .talk-page-bg {
        position: absolute;
        top: 227px;
        left: 0;
        width: 100%;
      }

      .talk-page-wrap {
        height: 753px;
      }

      .talk-page {
        position: absolute;
        top: 60px;
        left: 150px;
        width: 320px;
        height: 693px;
      }

      .float-man {
        position: absolute;
        left: -100px;
        top: 373px;
        width: 357px;
        height: 357px;
        animation: mananimation 3s ease-in-out infinite;
        -webkit-animation: mananimation 3s ease-in-out infinite;
      }

      @keyframes mananimation {
        0% {
          transform: translate(0px, 0px);
        }

        50% {
          transform: translate(0px, -20px);
        }

        100% {
          transform: translate(0px, 0px);
        }
      }

      @-webkit-keyframes mananimation {
        0% {
          transform: translate(0px, 0px);
        }

        50% {
          transform: translate(0px, -20px);
        }

        100% {
          transform: translate(0px, 0px);
        }
      }
    }
  }

  .real-life {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin: 40px 0 0 0;

    .real-life-text {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 640px;
      gap: 12px;
      margin: 60px 0 0 0;
    }

    .real-life-text .title {
      font-family: LexendDeca-ExtraBold;
      font-size: 52px;
      line-height: 80px;
    }

    .real-life-text .sub-title {
      font-family: LexendDeca-Regular;
      font-size: 18px;
    }

  }

  .card-list {
    max-width: 1920px;
    margin-top: 60px;
    width: 100%;
    display: inline-flex;
    justify-content: center;

    .card-item {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-left: 20px;
      width: 380px;
      box-shadow: 0px 2px 25px 0px #DBE2EA33;
      background: #fff;
      border-radius: 20px;

      img {
        width: 56px;
        border-radius: 50%;
      }

      .card-title {
        margin: 8px 0 20px;
        font-size: 18px;
        font-family: LexendDeca-Bold;
        font-weight: 500;
        text-align: left;
        color: #0C1D3E;
      }

      .card-content {
        position: relative;
        font-size: 17px;
        font-family: LexendDeca-Regular;
        color: #243556;
        text-align: left;

        img {
          position: absolute;
          left: -7px;
          top: -4px;
          width: 24px;
        }
      }
    }
  }

  .bottom-text {
    margin-top: 24px;
    font-size: 15px;
    font-family: sans-serif;
    color: #6B7486;
    padding: 0 0 150px 0;
  }

  .title {
    font: 52px LexendDeca-Bold;
    color: #111111;
  }

  .desc {
    margin-top: 24px;
    font-size: 18px;
    font-family: LexendDeca-Regular;
    color: #4B5463;
  }


}

@module-left-img-height: 440px;

.module-real-time {
  .module-img-left-style(180px, '../../assets/image/speaking/talk-about-anythingin.png');
}

.module-img-left-style(@text-margin-top, @background-image-url) {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  margin-bottom: 71px;
  margin-left: 353px;
  margin-right: 370px;
  height: @module-left-img-height;
  background-image: url(@background-image-url);
  flex: 1;
  background-repeat: no-repeat;
  /* 禁止背景图像重复 */
  background-size: contain;
  /* 调整背景图像尺寸以适应容器 */

  .right-text-real-time {
    position: relative;
    margin-top: 60px;
    width: 100%;
    text-align: right;
  }

  .left-image-real-time {
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: @module-left-img-height;
    width: 100%;
  }
}
</style>