<template>
    <div class="talkme-content">
        <content-get-start></content-get-start>
        <content-comments></content-comments>
        <content-letter></content-letter>
         <content-ai-tour></content-ai-tour>
        <contentSpeaking></contentSpeaking>
        <contentBetterAI></contentBetterAI>
        <contentBottom></contentBottom>
    </div>
</template>

<script>

import contentGetStart from './Content/ContentGetStart.vue'
import ContentComments  from './Content/ContentComments.vue';
import ContentLetter from  './Content/ContentLetter.vue';
import ContentAiTour from  './Content/ContentAiTour.vue';
import contentSpeaking from './Content/ContentSpeaking.vue';
import contentBetterAI from './Content/ContentBetterAI.vue';
import contentBottom from './Content/ContentBottom.vue';

export default {
    components: {
        contentGetStart,
        ContentComments,
        ContentLetter,
        ContentAiTour,
        contentSpeaking,
        contentBetterAI,
        contentBottom,
    }
}

</script>

<style>
.talkme-content{
    max-width: 1920px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
</style>