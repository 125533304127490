<template>
 <div id="your-brand-voice-target" class="section_sticky-sections-tbx">
        <div class="sticky-sections-tbx">
          <div class="sticky-section-tbx">
            <div class="sticky-section_background-tbx sticky-section_background-tbx-1"></div>
            <div class="padding-section-community-tbx">
              <div class="container-tbx">
                <div class="sticky-section_content-tbx">
                  <div class="sticky-section_paragraph-tbx">
                    <span ref="myText" class="split-lines-black">Want to practice a new language</span>
                    <span ref="myText" class="split-lines-black">with native speakers</span>
                    <span ref="myText" class="split-lines-black">but find it challenging?</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sticky-section-tbx">
            <div class="sticky-section_background-tbx sticky-section_background-tbx-2"></div>
            <div class="padding-section-community-tbx">
              <div class="container-tbx">
                <div class="sticky-section_content-tbx">
                  <div class="sticky-section_paragraph-tbx">
                    <span ref="myText" class="split-lines-white">With TalkMe</span>
                    <span ref="myText" class="split-lines-white">you can boost your language skills</span>
                    <span ref="myText" class="split-lines-white">by simply chatting with AI partners</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sticky-section_proxy-triggers-tbx">
          <div data-w-id="14cfa1cf-fce6-8c87-1e7f-2db67be5127a"
            class="sticky-section_proxy-trigger-tbx sticky-section_proxy-trigger-tbx-1"></div>
          <div data-w-id="14cfa1cf-fce6-8c87-1e7f-2db67be5127b"
            class="sticky-section_proxy-trigger-tbx sticky-section_proxy-trigger-tbx-2"></div>
        </div>
  </div>
</template>

<script>

import { gsap } from "gsap"
import StickySection from "../../assets/js/StickySection.js"
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  data() {
    return {
      animationInstances:[{"letter-group-0":false},{"letter-group-1":false}],
      currentScroll: 0,
      isFirstSection: 0,
      activeClass: 'sticky-section-active-tbx',
      visibleClass: 'sticky-section-visible-tbx',
      animationDuration: 0.7,
      scrollTrigger: null,
      isDesktop: false,
      stickySectionsWrapper: null,
      sectionTriggerEls: null,
      stickySectionEls: null,
      voiceCopyEl:null,
      index: 0,
      stickySectionInstances: [],
    }
  },

  methods: {
    init() {
      window.addEventListener('resize', () => {
        clearTimeout(this.resizeTimer)
        this.resizeTimer = setTimeout(() => {
          this.handleResize()
        }, 250)
      })
      if (this.isDesktop && this.sectionTriggerEls && this.stickySectionEls) {
        this.setupStickySections()
        // this.setupProgressAnimations()
      }
    },
    addLeadZeros(num, maxZeros = 1) {
      return '0'.repeat(maxZeros) + num
    },

    handleResize() {
      this.isDesktop = this.getIsDesktop()
      this.updateStickySections()
      ScrollTrigger.refresh()
    },

    setupStickySections() {
      this.sectionTriggerEls.forEach((triggerEl, index) => {
        const contentEl = this.stickySectionEls[index]
        const stickySectionInstance = new StickySection(triggerEl, contentEl, index,this.animationInstances)
        this.stickySectionInstances.push(stickySectionInstance)
      })
    },

    refreshStickySections() {
      this.stickySectionInstances.forEach((instance) => {
        if (instance.scrollTrigger) {
          instance.scrollTrigger.refresh()
        }
      })
    },

    destroyStickySections() {
      this.stickySectionInstances.forEach((instance) => {
        if (instance.scrollTrigger) {
          instance.scrollTrigger.kill()
          instance.clearAllProps()
        }
      })
      this.stickySectionInstances = []
    },

    updateStickySections() {
      if (this.isDesktop) {
        if (this.stickySectionInstances.length) {
          this.refreshStickySections()
        } else if (this.sectionTriggerEls && this.stickySectionEls) {
          this.setupStickySections()
        }
      } else {
        if (this.stickySectionInstances.length) {
          this.destroyStickySections()
        }
      }
    },
    getIsDesktop() {
      return window.matchMedia('(min-width: 992px)').matches
    },
    getWindowHeight() {
      return window.innerHeight
    },
    fadeInElements(elements, delay = 0, stagger = 0) {
      gsap.fromTo(
        elements,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 0.7, stagger: stagger, delay: delay }
      )
    },
    fadeOutElements(elements) {
      gsap.fromTo(
        elements,
        { opacity: 1, y: 0 },
        { opacity: 0, y: -50, duration: 0.3 }
      )
    }
  },
  mounted() {
    this.isDesktop = this.getIsDesktop()
    this.stickySectionsWrapper = document.querySelector('.section_sticky-sections-tbx');
    this.stickySectionEls = this.stickySectionsWrapper.querySelectorAll('.sticky-section-tbx')
    this.sectionTriggerEls = this.stickySectionsWrapper.querySelectorAll('.sticky-section_proxy-trigger-tbx')
    this.init();
  }
}
</script>
<style lang="less" scoped>

 .sticky-section_paragraph-tbx{
  position: relative;
  gap: 28px;
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* 水平均匀分布 */
  flex-direction: column; /*使元素垂直排列*/
}

.section_sticky-sections-tbx {
    width: 100%;
    height: 100vh;
    position: relative
}

.container-tbx {
  max-width: 1920px;
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: relative
}


sticky-section_content-tbx {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex
}

.split-lines-black{
  font-family: LexendDeca-ExtraBold;
  font-size: 60px;
  color: #0C1D3E;
  max-width: 1180px;
}

.split-lines-white{
  font-family: LexendDeca-ExtraBold;
  font-size: 60px;
  color: white;
}

@media screen and (min-width: 1920px) {
  .padding-section-community-tbx {
        border-radius: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 96px;
        padding-right: 96px;
        display: block
    }

    .sticky-section_content-tbx {
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex
    }
}


.sticky-section_background-tbx {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%
}

  /* sticky-sections styles */
@media screen and (min-width: 992px) {
    .section_sticky-sections-tbx {
      height: clamp(3600px, 300vh, 4800px);
    }

    .sticky-sections-tbx {
      height: 100vh;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .padding-section-community-tbx {
        border-radius: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        display: block
    }


    .sticky-section_content-tbx {
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex
    }
    
    .sticky-section_background-tbx.sticky-section_background-tbx-1,.sticky-section_background-tbx.sticky-section_background-tbx-1 {
        background-color: white
    }

    .sticky-section_background-tbx.sticky-section_background-tbx-2 {
        background-color: #3478F6
    }

    .sticky-section-tbx {
      position: absolute;
      pointer-events: none;
      inset: 0;
      width: 100%;
    }

    .container-tbx {
        display: flex;
        position: relative
    }

    .sticky-section-tbx .container-tbx {
      display: none;
    }

    .sticky-section-tbx.sticky-section-visible-tbx .container-tbx {
      display: block;
    }

    .sticky-section_background-tbx {
      will-change: transform;
    }
}

@media screen and (min-width: 992px) and (max-height: 700px) {
      .sticky-section_content-tbx {
        padding-top: 120px;
      }

      .padding-section-community-tbx {
        border-radius: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        display: block
    }

    .sticky-section_content-tbx {
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
        padding-bottom: 80px;
        display: flex
    }


    .sticky-section_background-tbx.sticky-section_background-tbx-1,.sticky-section_background-tbx.sticky-section_background-tbx-1.headings-style-h2-tbx {
    background-color: white
    }

    .sticky-section_background-tbx.sticky-section_background-tbx-2 {
        background-color: #3478F6
    }
    
}
   

</style>