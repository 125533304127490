<template>
  <div id="app">
    <home-header></home-header>
    <home-content></home-content>
    <home-footer></home-footer>
  </div>
</template>

<script>
import HomeHeader from './components/HomeHeader.vue';
import HomeFooter from './components/HomeFooter.vue';
import HomeContent from './components/HomeContent.vue';
export default {
  name: 'App',
  components: {
    HomeHeader,
    HomeFooter,
    HomeContent,
  }
}
</script>

<style>
@import './assets/css/fonts.css';

#app {
  font-family: LexendDeca-Regular, LexendDeca-ExtraBold, LexendDeca-Bold, Poppins-Bold;
  -webkit-font-smoothing: LexendDeca-Regular;
  -moz-osx-font-smoothing: LexendDeca-Regular;
  text-align: center;
  color: #2c3e50;
  display: flex;
  justify-content: center;
}

body {
  font-family: LexendDeca-Regular, sans-serif;
  margin: 0px;
}
</style>
