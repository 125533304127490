<template>
  <div class="content-better-ai">
    <div class="header-title">A better Al for Language learning</div>
    <div class="section-better-ai">
      <div class="module-sentence-translation">
        <div class="left-text-sentence">
          <img class="shadow-bg" src="../../assets/image/betterAi/text-bg-bule.png" alt="">
          <div class="title">Sentence translation & Vocabulary expansion</div>
          <div class="desc">Translate and collect unfamiliar words and sentences at<br /> any time. Also convenient for
            review!</div>
          <img class="voice" src="../../assets/image/betterAi/voice.png" alt="">
        </div>
        <div class="right-sentence-translation">
          <div class="talk-page-bg"></div>
        </div>
      </div>
      <div class="module-real-time">
        <div class="left-image-real-time">
          <div class="real-time-background-image"></div>
        </div>
        <div class="right-text-real-time" style="margin-top: 130px;">
          <div class="title">Real-time evaluation & feedback</div>
          <div class="desc">AI partners can help you correct every single detail in your conversation.</div>
          <img class="arrow" src="../../assets/image/betterAi/arrow.png" alt="">
        </div>
      </div>
      <div class="module-intelligent-hints">
        <div class="left-text-sentence">
          <div class="title">Intelligent conversation hints</div>
          <div class="desc">Don't know how to respond? The hints will guide you to further conversations.</div>
        </div>
        <div class="right-sentence-translation">
          <div class="talk-page-bg"></div>
        </div>
      </div>
      <div class="module-native-language">
        <div class="left-image-real-time">
          <div class="real-time-background-image"></div>
        </div>
        <div class="right-text-real-time" style="margin-top: 130px;">
          <div class="title">Native language translation</div>
          <div class="desc">If you find it hard to express yourself, you can use the "translate" section to convert your
            native language into another language.</div>
        </div>
      </div>
      <div class="card-wrap">
        <div class="card-list">
          <div class="card-item" v-for="(item, index) in cardList" :key="index">
            <img :src="item.url" alt="">
            <span class="card-title">{{ item.label }}</span>
            <div class="card-content">
              <img src="../../assets/image/speaking/symbol.png" alt="">
              <div>{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="bottom-text">—— From users real feeling. (The comments have been translated) ——</div>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  data() {
    return {
      cardList: [{
        label: 'James Foster',
        url: require('../../assets/image/speaking/header4.png'),
        content: "The best function is that I could say in my native language, and it can translate into my learning language instantly."
      },
      {
        label: 'LaDolceVita',
        url: require('../../assets/image/speaking/header5.png'),
        content: "The error correction is awesome! I couldn't find a real tutor to correct every sentence for me."
      },
      {
        label: 'さとう なつみ',
        url: require('../../assets/image/speaking/header6.png'),
        content: "I like the Full sentence translation and vocabulary expansion. It can help me memorize words, and I could see how the word is used in a sentence!"
      }],
    }
  }
}) 
</script>
<style lang="less" scoped>
.content-better-ai {
  margin-top: 120px;
  max-width: 1920px;
  width: 100%;
  background-image: url('../../assets/image/betterAi/better-background.png');
  background-repeat: no-repeat;
  /* 禁止背景图像重复 */
  background-size: contain;
  /* 调整背景图像尺寸以适应容器 */
  background-position: center center;
  /* 调整背景图像的位置为居中 */

  .header-title {
    font-size: 52px;
    font-family: Poppins-Bold;
    font-weight: 700;
    color: #111111;
  }

  .section-better-ai {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

  }

  @sentence-translation-height: 680px;
  @sentence-real-time: 664px;

  // @media screen and (min-width: 1366px)  {
  //   @sentence-translation-height:470px
  // }

  .module-img-right-style(@text-margin-top, @background-image-url) {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    margin-left: 370px;
    margin-right: 305px;
    height: @sentence-translation-height;
    background-image: url(@background-image-url);
    flex: 1;
    background-repeat: no-repeat;
    /* 禁止背景图像重复 */
    background-size: contain;
    /* 调整背景图像尺寸以适应容器 */
    background-position-x: 480px;

    .left-text-sentence {
      position: relative;
      margin-top: @text-margin-top;
      width: 100%;
      text-align: left;

      .shadow-bg {
        position: absolute;
        top: -60px;
        left: -70px;
        width: 384px;
      }

      .voice {
        margin-top: 96px;
        width: 160px;
        height: 30px;
        padding-left: 25px;
      }

      // .arrow {
      //   margin: 96px 45px 0 0;
      //   width: 160px;
      //   height: 30px;
      // }

      .ai-tutor {
        margin: 110px 0 0 -80px;
        width: 480px;
      }
    }

    .right-sentence-translation {
      position: relative;
      display: flex;
      justify-content: flex-start;
      height: @sentence-translation-height;
      width: 100%;
    }
  }

  .module-img-left-style(@text-margin-top, @background-image-url) {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    margin-left: 350px;
    margin-right: 370px;
    height: @sentence-real-time;
    background-image: url(@background-image-url);
    flex: 1;
    background-repeat: no-repeat;
    /* 禁止背景图像重复 */
    background-size: contain;
    /* 调整背景图像尺寸以适应容器 */

    .right-text-real-time {
      position: relative;
      margin-top: 180px;
      width: 100%;
      text-align: right;
    }

    .left-image-real-time {
      position: relative;
      display: flex;
      justify-content: flex-start;
      height: @sentence-real-time;
      width: 100%;
    }

    .arrow {
      margin: 96px 45px 0 0;
      width: 160px;
      height: 30px;
    }

  }

  .module-sentence-translation {
    .module-img-right-style(160px, "../../assets/image/betterAi/translation-big@2x.png");
  }

  .module-real-time {
    .module-img-left-style(180px, '../../assets/image/betterAi/real-time@2x.png');
  }

  .module-intelligent-hints {
    .module-img-right-style(160px, "../../assets/image/betterAi/sentence-translation@2x.png");
  }

  .module-native-language {
    .module-img-left-style(180px, '../../assets/image/betterAi/evaluation-bg.png');
  }

  .title {
    font: 46px LexendDeca-Bold;
    color: #111111;
  }

  .desc {
    margin-top: 24px;
    font-size: 18px;
    font-family: LexendDeca-Regular;
    color: #243556;
  }










  .module {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    .left-text {
      position: relative;
      margin-top: 100px;
      width: 100%;
      text-align: left;

      &.right {
        text-align: right;
      }

      .shadow-bg {
        position: absolute;
        top: -60px;
        left: -70px;
        width: 384px;
      }

      .title {
        font: 46px LexendDeca-Bold;
        color: #111111;
      }

      .desc {
        margin-top: 24px;
        font-size: 18px;
        font-family: LexendDeca-Regular;
        color: #243556;
      }

      .voice {
        margin-top: 64px;
        width: 230px;
      }

      .arrow {
        margin: 100px 240px 0 0;
        width: 148px;
      }

      .ai-tutor {
        margin: 110px 0 0 -80px;
        width: 480px;
      }
    }



    .right-images {
      position: relative;
      display: flex;
      justify-content: flex-start;
      height: 650px;
      width: 100%;

      .talk-page-bg {
        background-image: url('../../assets/image/betterAi/sentence-translation@2x.png');
        flex: 1;
        background-repeat: no-repeat;
        /* 禁止背景图像重复 */
        background-size: contain;
        /* 调整背景图像尺寸以适应容器 */
        background-position: center top;
        /* 调整背景图像的位置为居中 */
        height: 100%;
      }



      .tutor-background-image {
        background-image: url('../../assets/image/betterAi/translation-big@2x.png');
        flex: 1;
        background-repeat: no-repeat;
        /* 禁止背景图像重复 */
        background-size: contain;
        /* 调整背景图像尺寸以适应容器 */
        background-position: center top;
        /* 调整背景图像的位置为居中 */
        height: 100%;
      }

      .talk-page {
        position: absolute;
        top: -100px;
        left: 260px;
        width: 300px;
      }

      .translation {
        position: absolute;
        left: 80px;
      }

      .play {
        position: absolute;
        left: -50px;
        top: 220px;
      }
    }

    .real-time {
      margin-right: 148px;
      width: 492px;

      .evaluation-bg {
        width: 100%;
      }

      .grammar {
        position: absolute;
        top: 1px;
        left: 10px;
        width: 400px;
      }

      .rating {
        position: absolute;
        top: 390px;
        right: -68px;
        width: 400px;
        height: 298px;
      }

      &>div {
        position: absolute;
        padding: 14px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 26px 35px 0px #30363F1A;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 39px;
        color: #FF6183;
        box-sizing: border-box;
      }

      .grammar-text {
        width: 116px;
        right: 43px;
        top: 14px;
      }

      .rating-text {
        width: 150px;
        right: -85px;
        bottom: 155px;
      }
    }

    .tutor {
      .talk-page-bg {
        left: 37px;
        top: 25px;
      }

      .talk-page {
        left: 145px;
      }

      .hints {
        position: absolute;
        left: 35px;
        bottom: 0;
      }

      .arrow-blue {
        position: absolute;
        left: 90px;
        bottom: 60px;
        width: 149px;
      }

      .tutor-card {
        position: absolute;
        left: -190px;
        top: 220px;
      }
    }

    .native {
      .evaluation-bg {
        width: 536px;
      }

      .talk-page {
        left: 50px;
        top: -40px;
      }

      .translate {
        position: absolute;
        bottom: 51px;
        left: 150px;
      }

      .arrow-blue {
        position: absolute;
        bottom: 147px;
        left: 350px;
        transform: rotateY(180deg);
      }

      .native-card {
        position: absolute;
        bottom: 14px;
        right: -255px;
      }
    }
  }

  .card-wrap {
    padding: 60px 0 136px;
    width: 100%;
    background: url('../../assets/image/betterAi/bottom-card-bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .card-list {
    text-align: left;
    display: flex;
    justify-content: center;

    .card-item {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-left: 20px;
      width: 380px;
      box-shadow: 0px 2px 25px 0px #DBE2EA33;
      background: #fff;
      border-radius: 20px;

      img {
        width: 56px;
        border-radius: 50%;
      }

      .card-title {
        margin: 8px 0 20px;
        font-size: 18px;
        font-family: LexendDeca-Bold;
        font-weight: 500;
        color: #0C1D3E;
      }

      .card-content {
        position: relative;
        font-size: 17px;
        font-family: LexendDeca-Regular;
        color: #243556;
        line-height: 26px;

        img {
          position: absolute;
          left: -7px;
          top: -4px;
          width: 24px;
        }
      }
    }
  }


  .bottom-text {
    margin-top: 24px;
    font-size: 16px;
    font-family: LexendDeca-Regular;
    color: #6B7486;
  }
}
</style>