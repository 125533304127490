import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/src/SplitText"
// import GSDevTools from "gsap/src/GSDevTools"

/* Homepage Sticky Section Class */
class StickySection {
  constructor(triggerEl, contentEl, index, animations) {
    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.defaults({
      markers: false,
    })
    this.animations = animations
    this.triggerEl = triggerEl
    this.contentEl = contentEl
    this.index = index
    this.isFirstSection = index === 0
    this.scrollTrigger = null
    this.enterTl = gsap.timeline({ paused: true })
    this.exitTl = gsap.timeline({ paused: true })
    this.latterTimeline = gsap.timeline()
    this.allContentEls = document.querySelectorAll('.sticky-section-tbx')
    this.backgroundEl = this.contentEl.querySelector('.sticky-section_background-tbx')
    this.copyEl = this.contentEl.querySelector('.sticky-section_paragraph-tbx')
    this.talkeMeLines = contentEl.querySelectorAll('.split-lines-black')
    if (index) {
      this.talkeMeLines = contentEl.querySelectorAll('.split-lines-white');
      this.splitText = new SplitText(this.talkeMeLines, { type: 'lines', linesClass: 'split-lines-white' });
    } else {
      this.splitText = new SplitText(this.talkeMeLines, { type: 'lines', linesClass: 'split-lines-black' });
    }
    this.activeClass = 'sticky-section-active-tbx'
    this.visibleClass = 'sticky-section-visible-tbx'
    this.animationDuration = 0.7
    this.startPosition = 'top bottom'
    if (index) {
      this.startPosition = 'top bottom-=50%'
    }
    this.isShowLetter = false;

    this.init()
    // GSDevTools.create({paused:true});
  }

  init() {
    this.setupEnterAnimations()
    this.setupExitAnimations()
    this.setupScrollTriggers()
  }

  addLeadZeros(num, maxZeros = 1) {
    return '0'.repeat(maxZeros) + num
  }

  setupEnterAnimations() {
    // animate the background color div
    if (this.isFirstSection) {
      this.enterTl.fromTo(
        this.backgroundEl,
        { opacity: 0, y: '100%' },
        { opacity: 1, y: '0%', duration: 1 },
        0
      )
    } else {
      this.enterTl.fromTo(
        this.backgroundEl,
        { opacity: 0, y: '100%' },
        { opacity: 1, y: '0%', duration: 1, ease: 'expo.inOut' },
        0
      )
    }
    // animate the individual text elements with stagger
    this.enterTl.fromTo(
      [this.copyEl],
      { opacity: 0, y: 150 },
      { opacity: 1, y: 0, duration: this.animationDuration, stagger: 0.5 },
      0.5
    )
  }

  setupExitAnimations() {
    this.exitTl.to(
      [this.copyEl],
      { opacity: 0, duration: 0.3 }
    )
  }

  kill() {
    this.latterTimeline.clear().time(0);
    this.letterSplitText.revert();
  }

  setupScrollTriggers() {
    this.setVisibleSections([0])
    this.scrollTrigger = ScrollTrigger.create({
      trigger: this.triggerEl,
      start: this.startPosition,
      end: 'bottom top',
      // toggleClass: "red",
      // markers:true,
      preventOverlaps: !this.isFirstSection,
      fastScrollEnd: !this.isFirstSection,
      onEnter: () => {
        const fromAnimationVars = this.letterAnimationsFromVars();
        if(this.splitText != null){
          this.latterTimeline.set(this.splitText.lines, fromAnimationVars[0]); // 隐藏并将每行文字向下移动
          if (this.index) {
            this.latterTimeline.set(this.splitText.lines[0], fromAnimationVars[1]); // 隐藏并将每行文字向下移动
          }
        }
        
        const visibleSectionIndeces = [this.index - 1, this.index, this.index + 1]
        this.enterTl.restart()
        this.setVisibleSections(visibleSectionIndeces)
        setTimeout(() => {
          this.setActiveSection(this.index)
        }, 500)
        if (!this.isShowLetter) {
          this.isShowLetter = true;
          const animationToVars = this.letterAnimationstoVars()[this.index];
          if(this.splitText.lines != null){
            this.splitText.lines.forEach((line, index) => {
              this.latterTimeline.to(line, animationToVars[index]);
            });
          }
        }
      },
      onLeaveBack: () => {
        // force the enter animation to finish quickly if active
        gsap.to(this.enterTl, 0.2, { progress: 1 })
        this.exitTl.restart()
        this.isShowLetter = false;
        // animate the background color div back down
        if (this.isFirstSection) {
          gsap.fromTo(
            this.backgroundEl,
            { scaleX: 1.2, y: '0%' },
            { scaleX: 0.85, y: '100%', duration: 1, ease: 'power4.inOut' }
          )
        } else {
          gsap.fromTo(
            this.backgroundEl,
            { y: '0%' },
            { y: '100%', duration: 1, ease: 'expo.inOut' }
          )
        }
        // show the previous section again
        if (!this.isFirstSection) {
          setTimeout(() => {
            this.setActiveSection(this.index - 1)
            this.setVisibleSections([this.index - 1])
          }, 500)
        } else {
          this.contentEl.classList.remove(this.activeClass)
        }
      }
    })
  }

  setActiveSection(index) {
    if (this.progressNumActiveEl) this.progressNumActiveEl.innerHTML = this.addLeadZeros(index + 1)
    const activeContentEl = this.allContentEls[index]
    this.allContentEls.forEach((el) => {
      el.classList.remove(this.activeClass)
    })
    if (activeContentEl) activeContentEl.classList.add(this.activeClass)
  }

  setVisibleSections(elementIndeces) {
    this.allContentEls.forEach((el) => {
      el.classList.remove(this.visibleClass)
    })
    const visibleEls = Array.from(this.allContentEls).filter((_, index) => elementIndeces.includes(index))
    visibleEls.forEach((el) => {
      if (el) el.classList.add(this.visibleClass)
    })
  }

  fadeInElements(elements, delay = 0, stagger = 0) {
    gsap.fromTo(
      elements,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 0.7, stagger: stagger, delay: delay }
    )
  }

  fadeOutElements(elements) {
    gsap.fromTo(
      elements,
      { opacity: 1, y: 0 },
      { opacity: 0, y: -50, duration: 0.3 }
    )
  }

  clearAllProps() {
    gsap.set(
      [this.backgroundEl, this.copyEl],
      { clearProps: 'all' }
    )
  }
  letterAnimationstoVars() {
    return [
              [
                {
                opacity: 1,
                y: 0,
                duration: 1.5, // 动画持续时间
                stagger: 0.5, // 每行文字之间的延迟时间
                ease: 'sine.inOut', // 动画缓动效果
                }, {
                opacity: 1,
                y: 0,
                duration: 0.75, // 动画持续时间
                stagger: 0.4, // 每行文字之间的延迟时间
                ease: 'sine.inOut', // 动画缓动效果
                }, {
                opacity: 1,
                y: 0,
                duration: 0.95, // 动画持续时间
                stagger: 0.4, // 每行文字之间的延迟时间
                ease: 'sine.inOut', // 动画缓动效果
                }
              ], 
              [
                {
                opacity: 1,
                scale: 1,
                duration: 1.25, // 动画持续时间
                stagger: 0.5, // 每行文字之间的延迟时间
                ease: 'sine.inOut', // 动画缓动效果
                },{
                opacity: 1,
                y: 0,
                duration: 0.85, // 动画持续时间
                stagger: 0.5, // 每行文字之间的延迟时间
                ease: 'sine.inOut', // 动画缓动效果
                }, {
                opacity: 1,
                y: 0,
                duration: 0.8, // 动画持续时间
                stagger: 0.5, // 每行文字之间的延迟时间
                ease: 'sine.inOut', // 动画缓动效果
                }
              ]
    ]
  }
  letterAnimationsFromVars(){
    return [
      { opacity: 0, y: 25 }, { opacity: 0, y: 0, scale: 0 }
    ]
  }
}
export default StickySection