<template>
    <div class="header" v-on:mouseleave="hideTooltip">
        <div class="header-group">
            <div class="head-logo">
                <img src="../assets/logo.png" />
                <img src="../assets/image/getstart/talkeme.png" />
            </div>
            <div class="group_menu">
                <div>
                    <span @click="navToBlogHandler">Blog</span>
                </div>
                <div>
                    <span @click="contactHandler">Contact</span>
                </div>
                <div v-on:mouseenter="showTooltip" @click="showDownload">
                    <span>Download App</span>
                    <div v-if="show" class="tooltip">
                        <div>
                            <img v-for="item in downloadList" :key="item.id" :src="item.thumb"
                                @click="openTargetWithUrl(item.downloadURLString)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            downloadList: [
                { id: 0, thumb: require('../assets/image/menu/top_app_store.png'), downloadURLString: "https://apps.apple.com/cn/app/id6449895215?mt=12" },
                { id: 1, thumb: require('../assets/image/menu/top_google_store.png'), downloadURLString: "https://play.google.com/store/apps/details?id=com.talkme.ai.language" }
            ],
        }
    },
    methods: {
        openTargetWithUrl(urlstring) {
            window.open(urlstring, 'target');
        },
        contactHandler() {
            window.open('mailto:talkme2023@163.com', 'target');
        },
        navToBlogHandler() {
            window.open('https://blog.talkme.ai/', 'target');
        }, showTooltip() {
            this.show = true;
        },
        hideTooltip() {
            this.show = false;
        },
        showDownload() {
            this.show = !this.show;
        }
    }
}
</script>

<style lang="less" scoped>
@headerHeight: 120px;
@header-group-margin: auto 8px;

.tooltip {
    position: absolute;
    height: @headerHeight;
    margin-top: 50px;
    background-color: #fff;
    border: 2px solid #EBF1F6;
    border-radius: 24px;
    fill: rgba(255, 255, 255, 0.96);
    stroke-width: 2px;
    stroke: var(--, #EBF1F6);
    filter: drop-shadow(0px 16px 24px rgba(176, 189, 218, 0.28));
    display: flex;

    &>div {
        cursor: pointer;
        display: inline-flex;
        vertical-align: middle;
        justify-content: space-around;
        margin: auto 10px;

        &>img {
            position: relative;
            margin: auto 10px;
            width: 213px;
            height: 62px;
        }
    }
}

.tooltip:before {
    position: absolute;
    content: "";
    top: -10px;
    left: 50%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #EBF1F6;
}

/* 白色覆盖*/
.tooltip:after {
    position: absolute;
    content: "";
    /*减少两像素*/
    top: -8px;
    left: 50%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}

.header {
    max-width: 1920px;
    background: rgba(255, 255, 255, 0.90);
    backdrop-filter: blur(0.875rem);
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    height: @headerHeight;
    width: 100%;
    z-index: 9999;

    .header-group {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-around;

        .head-logo {
            display: flex;
            justify-content: center;
            text-align: center;
            vertical-align: middle;
            margin: @header-group-margin;

            &>:first-of-type {
                width: 56px;
                height: 32px;
            }

            &> :last-of-type {
                padding: 4px 0 0 6px;
                width: 100px;
                height: 24px;
            }
        }

        .group_menu {
            display: flex;
            justify-content: center;
            gap: 40px;
            margin: auto 4px;

            &>div {
                display: inline-flex;
                margin: auto 16px;
                cursor: pointer;

                &>span {
                    color: #243556;
                    font-size: 18px;
                    font-family: LexendDeca-Regular;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }

            &>div:last-of-type {
                display: inline-flex;
                background: #3478F6;
                justify-content: center;
                vertical-align: middle;
                padding: 12px 20px;
                border-radius: 12px;

                &> :last-of-type {
                    color: #fff;
                    font-size: 16px;
                }
            }
        }
    }
}


// /* 移动端样式 */
// @media screen and (max-width: 768px) {
//     @headerHeight : 60px;
//     @header-group-margin:auto 20px;
//     .header {
//         background: rgba(255, 255, 255, 0.90);
//         backdrop-filter: blur(0.875rem);
//         position: fixed;
//         display: flex;
//         justify-content: center;
//         text-align: center;
//         height: @headerHeight;
//         width: 100%;
//         z-index: 9999;
//         .header-group{
//             width: 100%;
//             position: relative;
//             display: flex;
//             justify-content: space-around;
//             .head-logo{
//             display: flex;
//             justify-content: center;
//             text-align: center;
//             vertical-align: middle;
//             margin: @header-group-margin;
//             & >:first-of-type{
//                 width:40.5px;
//                 height:24px;
//             }
//             & > :last-of-type{
//                 padding: 4px 0 0 6px;
//                 width: 75px;
//                 height: 18px;
//             }
//         }

//         .group_menu{
//             display: flex;
//             justify-content: center;
//             margin: auto 4px;
//             & > div{
//                 display: inline-flex;
//                 margin: auto 16px; 
//                 cursor: pointer;
//                 & > span{
//                     color: #243556;
//                     font-size: 14px;
//                     font-family: LexendDeca-Regular;
//                     white-space:nowrap;
//                     cursor: pointer;
//                 }
//             }
//             & > div:first-of-type {
//                 display: none;
//             }
//             & > div:last-of-type{
//                     display: inline-flex;
//                     background: #3478F6;
//                     justify-content: center;
//                     vertical-align: middle;
//                     padding: 6px 16px; 
//                     border-radius: 10px;
//                     & > :last-of-type{
//                         color: #fff;
//                         font-size: 14px;
//                     }
//                 }
//             }
//         }
//     }
// }


// @media screen and (min-width: 1280px) {
// }

// @media screen and (min-width: 1440px) {
// }

// @media screen and (min-width: 1920px) {
//     max-width: 1920px;
// }

// @media screen and (max-width: 991px) {
//     @headerHeight : 120px;
//     @header-group-margin:auto 20px;
// }

// @media screen and (max-width: 767px) {
//     @headerHeight : 120px;
//     @header-group-margin:auto 20px;
// }

// @media screen and (max-width: 479px) {
//     @headerHeight : 120px;
//     @header-group-margin:auto 20px;

// }
</style>