<template>
  <div class="content-bottom">
    <div class="based-bg">
      <div class="based">
        <div class="title">Science-based learning model</div>
        <div class="desc-sub">TalkMe's global research center is dedicated to creating the optimal environment for
          language learning. By incorporating Function, Situation, and Topic into AI training, we strive to attain the
          most effective learning outcomes.</div>
        <div class="blog-btn">
          <span>Learn more </span>
          <img src="../../assets/image/bottom/arrow-right.png" alt="">
        </div>
        <img class="triangle-img" src="../../assets/image/bottom/sanjiao.png" alt="">
      </div>
    </div>
    <div class="retains">
      <div class="title">AI partners in your pocket</div>
      <div class="img-group">
        <img class="img-group-ai-partners" src="../../assets/image/bottom/retains1.png" alt="">
        <img class="img-group-normal-partners" src="../../assets/image/bottom/retains2.png" alt="">
      </div>
    </div>
    <div class="bottom-believe">
      <video ref="video" class="video-bg" autoplay muted loop playsinline>
        <source src="../../assets/image/bottom/video-bg.mp4" type="video/mp4">
      </video>
      <div class="above-content">
        <div class="b-content">Get ready to soar in new languages with AI partners!</div>
        <div class="btn-group">
          <img v-for="item in  downloadList " :key="item.id" class="download-group-img" :src="item.thumb"
            @click="jumpToURL(item.downloadURLString)">
        </div>
      </div>
    </div>
    <div class="icon-group">
      <div class="left-link">
        <span v-for=" item  in  baseInfoList " :key="item.id" @click="jumpToURL(item.URLString)">{{ item.title }}</span>
      </div>
      <div class="icon-link">
        <img v-for=" item  in  siteSupports " :key="item.id" :src="item.thumb" @click="jumpToURL(item.URLString)">
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  data() {
    return {
      downloadList: [
        { id: 0, thumb: require('../../assets/image/bottom/appstore.png'), downloadURLString: "https://apps.apple.com/cn/app/id6449895215?mt=12" },
        { id: 1, thumb: require('../../assets/image/bottom/googlepay.png'), downloadURLString: "https://play.google.com/store/apps/details?id=com.talkme.ai.language" }
      ],
      baseInfoList: [
        { "id": 0, "URLString": "https://www.talkme.ai/terms.html", "title": "Terms of Use" },
        { "id": 1, "URLString": "https://www.talkme.ai/privacy.html", "title": "Privacy Notice" },
        { "id": 2, "URLString": "mailto:talkme2023@163.com", "title": "Contact Us" }
      ],
      siteSupports: [
        { id: 0, URLString: "https://www.tiktok.com/@talkmeai", thumb: require('../../assets/image/bottom/tiktok.png') },
        { id: 1, URLString: "https://www.youtube.com/@talkmeai", thumb: require('../../assets/image/bottom/youtube.png') },
        { id: 2, URLString: "https://www.facebook.com/talkmeai", thumb: require('../../assets/image/bottom/facebook.png') },
        { id: 3, URLString: "https://www.instagram.com/talkme.ai/", thumb: require('../../assets/image/bottom/ins.jpg') }
      ]
    }
  },
  methods: {
    jumpToURL(webURLString) {
      window.open(webURLString, 'target');
    }
  }
})
</script>
<style lang="less" scoped>
.content-bottom {
  background-color: #fff;
  margin: 120px 0 0 0;
  width: 100%;
}

.bottom-step {
  width: 243px;
  margin: 0 auto;
}

.move-forward {
  position: relative;
  margin-top: -60px;
  font-size: 52px;
  font-weight: 700;
  font-family: Poppins-Bold;
  color: #0C1D3E;
  text-align: center;

  img {
    margin-top: 40px;
    width: 100%;
  }
}

.based-bg {
  background: rgb(249, 249, 249);
  width: 100%;
}

.based {
  padding: 60px 0 60px;
  margin: 0 auto;
  text-align: center;
  max-width: 1180px;

  .title {
    font-size: 52px;
    font-weight: 700;
    font-family: Poppins-Bold;
    color: #0C1D3E;
  }

  .desc-sub {
    margin-top: 8px;
    font-size: 18px;
    font-family: LexendDeca-Regular;
    color: #243556;
  }

  .blog-btn {
    margin: 16px auto 32px;
    background: #3478F6;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: LexendDeca-Bold;
    width: 184px;
    height: 46px;
    padding: 10px 24px 12px 24px;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;

    img {
      width: 20px;
      vertical-align: sub;
    }
  }

  .triangle-img {
    max-width: 1920px;
    width: 1200px;
    height: 700px;
  }
}

.retains {
  margin: 60px auto 0 auto;
  padding-bottom: 60px;
  max-width: 1180px;

  .title {
    font-size: 48px;
    font-weight: 700;
    font-family: Poppins-Bold;
    color: #111111;
  }

  .img-group {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-top: 32px;
    gap: 32px;

    .img-group-ai-partners {
      width: 580px;
      height: 564px;
    }

    .img-group-normal-partners {
      width: 480px;
      height: 480px;
      margin-top: 40px;
    }
  }
}

.bottom-believe {
  margin: 60px auto 120px;
  max-width: 1440px;
  position: relative;

  .video-bg {
    width: 100%;
  }

  .above-content {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .b-content {
    margin: 194px auto 0 auto;
    width: 1180px;
    font-size: 56px;
    font-weight: 700;
    font-family: Poppins-Bold;
    line-height: 100px;
    text-align: center;
    color: #FFFFFF;
  }

  .btn-group {
    margin: 72px auto 0;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 56px;

    .download-group-img {
      cursor: pointer;
      width: 220px;
      height: 64px;
    }
  }
}

.icon-group {
  margin: 120px auto 48px;
  display: flex;
  justify-content: space-between;
  width: 1180px;

  .left-link {
    display: flex;

    span {
      font-size: 18px;
      font-weight: 500;
      color: #243556;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 32px;
      }
    }
  }

  .icon-link {
    display: flex;

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }
}
</style>