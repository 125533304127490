<template>
    <div class="talkme_comments">
        <div class="group-comments-titles">
            <div class="talkme_all_recommended">
                All recommended
            </div>
            <div class="talkme_comment_title">
                <img :src="comment_left_url_string">
                <div>Beta users from 100+ countries</div>
                <img :src="comment_right_url_string">
            </div>
        </div>
        <div class="talkme_group_comment">
            <vue-seamless-scroll :data="comments" :class-option="classOption" class="warp">
                <ul class="ul-item">
                    <li class="li-item" v-for="(item, index) in comments" :key="index">
                        <div class="talkme_group_comment_item">
                            <div class="talkme_comment_item_dot"><img :src="comment_dot_image" /></div>
                            <div class="talkme_comment_item_icon">
                                <img style="width: 120px; height: 161px" :src="item.pic" />
                            </div>
                            <div class="talkme_comment_group_title">
                                <div class="talkme_item_title">{{ item.nickname }}</div>
                                <div class="talkme_item_flag">
                                    <img :src="item.nationalFlag" />
                                </div>
                            </div>
                            <div class="talkme_comment_item_desc">{{ item.comment }}</div>
                        </div>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>

        <div class="talkme_comment_feedback">
            <div></div>
            <div> Comments are all from our test community and feedback emails. </div>
            <div></div>
        </div>
    </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

import comment_left_image from '../../assets/comment_left_icon.png'
import comment_right_image from '../../assets/comment_right_icon.png'
import talkme_comment_dot from '../../assets/dot.png'
// import axios from 'axios'
import Swiper from 'swiper'

export default {
    components: {
        vueSeamlessScroll
    },
    watch: {
        //slide数据发生变化时,更新swiper
        comments: {
            deep: true,
            // eslint-disable-next-line
            handler(nv, ov) {
                console.log("comments 数据更新了");
                // this.updateSwiper();
            },
        },
    },
    data() {
        return {
            classOption: {
                limitMoveNum: 2,
                direction: 2
            },
            currentCommentSwiper: null,
            comment_left_url_string: comment_left_image,
            comment_right_url_string: comment_right_image,
            comment_dot_image: talkme_comment_dot,
            comments: [
                {
                    "id": 1,
                    "nickname": "SunnySurfer",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/8b19376400ad4fa5b5fd13e41db11ac1.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/440782788f814e59bbb32d3f197913ae.png",
                    "comment": "I've been tried lots of this kind of product, and this one is the best. I hope the developers gather others' good aspects and make a ultra one."
                }
                ,
                {
                    "id": 2,
                    "nickname": "JazzMaster",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/fc1469f8539640218c4d5788bed171ed.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/b5f92f8fca464e0a9ed234a62781860a.png",
                    "comment": "Recently, I tried almost AI oral practice APP, and TalkMe give me the best learn experience, especially grammer correction and local expression advice."
                },
                {
                    "id": 3,
                    "nickname": "LaDolceVita",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/eaa77ea44e7647969b9c91d3c3b37970.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/8b210b1fa1864916b55e81446c137b28.png",
                    "comment": "To be honest, when I use TalkMe, It can brings me the best experience than other similar product!"
                },
                {
                    "id": 4,
                    "nickname": "BaguetteLover",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/6193f8b1f0bf4bfe8d82cbe6f5a94dc3.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/2432975c4d6e4617ad621bc9927bf32b.png",
                    "comment": "It's really nice, I have tried 7 or 8 similar websites and APPs, they are not enough to compare with this!"
                },
                {
                    "id": 5,
                    "nickname": "WanderlustSoul",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/53daf4c3fbfd47cd82b9b79afe5d264c.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/c84acffbd88a42eabe9eb8effb0cd44d.png",
                    "comment": "The grammar explanations are concise and easy to understand, making complex concepts much more approachable."
                },
                {
                    "id": 6,
                    "nickname": "PixelNinja",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/e981c0f906ae43b99725c93636d06b10.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/cbef867e701d464d99057e76a385e993.png",
                    "comment": "I have a good experience! I could say that this APP focus on language learning, better than others!"
                },
                {
                    "id": 7,
                    "nickname": "MidnightDreamer",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/eaaaa6920b124d91a6b7e456da0f3f55.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/a0847b83639848fa8888ad66a749b4c0.png",
                    "comment": "Beautiful design, I like the color, and fully functional"
                },
                {
                    "id": 8,
                    "nickname": "MindfulZenMaster",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/7437c83e413542a79278cd96253264ab.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/2e59b39c084a484aacb27b6f8f91db35.png",
                    "comment": "This app has a wide range of language courses that cater to different interests and proficiency levels. It's perfect for learners of all backgrounds."
                },
                {
                    "id": 9,
                    "nickname": "ゆきかぜ",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/4a9baa6e1c7e440080a6659f24616fbd.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/12a73cad20f04b27a2bac930858d082b.png",
                    "comment": "The interactive speaking exercises with speech recognition are incredibly helpful. They've boosted my confidence in spoken English."
                },
                {
                    "id": 10,
                    "nickname": "DulceEsperanza",
                    "pic": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/f8acff142c314d5b90f16f9eb3c11059.png",
                    "nationalFlag": "https://d38qdauprblw1n.cloudfront.net/oss/upload/image/png/9c5ca635324541f6af7bf59bed8e3246.png",
                    "comment": "I appreciate the cultural part provided by this app. Learning English is not just about language, but also about understanding the culture."
                }
            ]
        }
    },
    methods: {
        //初始化swiper
        initSwiper() {
            // eslint-disable-next-line
            //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
            const swiper_comment = new Swiper(".swiper-wrapper", {
                slidesPerView: 3,
                spaceBetween: 10,
                loop: true,
                speed: 5500,//匀速时间2.5秒
                autoplay: {
                    //自动播放,不同版本配置方式不同
                    delay: 0,
                    stopOnLastSlide: false,
                    disableOnInteraction: true, // 用户操作之后是否停止自动轮播默认true
                },
                navigation: {
                    nextEl: ".swiper-button-next-r",
                    prevEl: ".swiper-button-next-l",
                }
            });
            swiper_comment.el.onmouseover = function (e) {
                console.log("onMouseenter" + e);
                swiper_comment.autoplay.stop()
                // this.Swiper.autoplay.stop();
            }, swiper_comment.el.onmouseout = function (e) {
                console.log("onMouseleave" + e);
                swiper_comment.autoplay.start()
            }
            this.currentCommentSwiper = swiper_comment;
        }, destroySwiper() {
            try {
                this.currentCommentSwiper.destroy(true, false);
            } catch (e) {
                console.log("删除轮播");
            }
        },
        //更新swiper（先销毁 再 重新初始化swiper）
        updateSwiper() {
            this.destroySwiper();
            this.$nextTick(() => {
                console.log("==== updateSwiper ");
                this.initSwiper();
            });
        },
        // 组件销毁之前 销毁 swiper 实例
        beforeDestroy() {
            this.destroySwiper();
        }
    },
    mounted() {
        // this.initSwiper();
    },
}
</script>

<style lang="less" scoped>
.talkme_comments {
    position: relative;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    flex-wrap: wrap;
    margin-top: 120px;

    .group-comments-titles {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .talkme_all_recommended {
            padding: 4px 14px;
            background: #FF6183;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 1px;
            color: white;
            font-size: 16px;
            font-family: LexendDeca-Regular;
            word-wrap: break-word;
        }

        .talkme_comment_title {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            margin-top: 9px;
        }

        .talkme_comment_title div {
            white-space: nowrap;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 52px;
            font-family: LexendDeca-ExtraBold;
        }

        .talkme_comment_title img {
            width: 28.99px;
            height: 57px;
        }

    }

    .talkme_group_comment {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 277px;
        margin: 40px auto auto auto;

        .warp {
            max-width: 1920px;
            ;
            width: 100%;
            height: 277px;
            overflow: hidden;

            ul {
                list-style: none;
                padding: 0;
                margin: 0 auto;

                &.ul-item {
                    display: flex;

                    .li-item {
                        border-radius: 20px;
                        margin-right: 20px;
                        background-color: white;
                        color: #fff;
                        text-align: center;
                        height: 277px;
                    }
                }
            }
        }
    }
}

.talkme_group_comment_item {
    box-sizing: border-box;
    padding-top: 120px;
    padding-bottom: 20px;
    width: 380px;
    height: 100%;
    position: relative;
    background: white;
    box-shadow: 0px 2px 25px rgba(219.14, 226.45, 233.75, 0.20);
    border-radius: 20px;
    border: 1px #EBF1F6 solid;
}

.talkme_comment_item_desc {
    left: 20px;
    margin-right: 30px;
    position: relative;
    color: #243556;
    font-size: 17px;
    font-family: LexendDeca-Regular;
    line-height: 25.50px;
    word-wrap: break-word;
    text-align: left;
}

.talkme_comment_item_dot {
    width: 24px;
    height: 17px;
    left: 13px;
    top: 118px;
    position: absolute;
}

.talkme_comment_item_icon {
    width: 56px;
    height: 56px;
    left: 20px;
    top: 20px;
    position: absolute;
    background: #E3F5EC;
    border-radius: 43.75px;
    overflow: hidden;
    border: 0.44px #EBF1F6 solid;
    justify-content: center;
    align-items: center;
    display: inline-flex
}

.talkme_comment_group_title {
    left: 20px;
    top: 84px;
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    display: inline-flex;
}

.talkme_item_title {
    color: #0C1D3E;
    font-size: 18px;
    font-family: LexendDeca-Bold;
    line-height: 18px;
    word-wrap: break-word;
}

.talkme_item_flag {
    width: 18px;
    height: 18px;
    border-radius: 16px;
    overflow: hidden;
    border: 0.50px #EBF1F6 solid;
    justify-content: center;
    align-items: center;
    display: flex;
}

.talkme_item_flag img {
    width: 18px;
    height: 18px;
}

.talkme_comment_feedback {
    position: relative;
    align-items: center;
    display: inline-flex;
    margin-top: 20px;
    font-family: LexendDeca-Regular;
    font-size: 15px;
    word-wrap: break-word;
    color: #A8AEBA;
}

.talkme_comment_feedback div:first-child {
    width: 16px;
    height: 0px;
    border: 0.50px #A8AEBA solid;
    margin-right: 5px;
}

.talkme_comment_feedback div:last-child {
    width: 16px;
    height: 0px;
    border: 0.50px #A8AEBA solid;
    margin-left: 5px;
}

.talkme_comment_group_buttons {
    top: 40px;
    position: relative;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
}

.talkme_comment_group_buttons div:first-child {
    width: 60px;
    height: 60px;
    background: #3478F6;
    border-radius: 9999px;
    border: 0.50px solid;
    margin-left: 24px;
    margin-right: 24px;
}

.talkme_comment_group_buttons div:last-child {
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 9999px;
    border: 0.50px solid;
    margin-left: 24px;
    margin-right: 24px;
}
</style>