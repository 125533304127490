import Vue from 'vue'
import App from './App.vue'

// 引入 swiper.css
// import 'amfe-flexible'
import "swiper/css/swiper.css"
import './assets/css/fonts.css'
Vue.config.productionTip = true

new Vue({
  render: h => h(App),
}).$mount('#app')