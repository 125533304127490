<template>
  <div class="talkme_practice">
    <div class="talkme_practice_part_1">
      <div class="talkme_practice_title">
        <span>Practice anytime & anywhere</span>
        <div class="talkme_practice_desc">Without any limit, even just 5 minutes of daily practice can lead to progress.
        </div>
      </div>
      <div class="talkme_practice_img">
        <div><img class="talkme_practice_img_chat" src="../../assets/image/practice/chat_message.png"></div>
        <div><img class="practice-projector-lamp" src="../../assets/image/practice/chat_message_background.png"></div>
        <div><img class="talkme_practice_img_anytime" src="../../assets/image/practice/anytime_personal.png"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.talkme_practice {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
}

.talkme_practice_part_1 {
  position: relative;
}

.talkme_practice_title {
  position: relative;
}

.talkme_practice_desc {
  position: relative;
  margin-top: 24px;
  color: #4B5463;
  font: 18px LexendDeca-Regular;
}

.talkme_practice_part_1 span {
  position: relative;
  text-align: center;
  color: #0C1D3E;
  font: 52px LexendDeca-ExtraBold;
  letter-spacing: 1px;
  word-wrap: break-word;
}

.talkme_practice_img {
  position: relative;
  margin-top: 78px;
  margin-bottom: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.talkme_practice_img_chat {
  width: 316px;
  height: 523px;
  margin-right: -42px;
}

.practice-projector-lamp {
  width: 316px;
  height: 523px;
  margin-right: -173px;
}

.talkme_practice_img_anytime {
  width: 652px;
  height: 372px;
}

.talkme_practice_part_2 {
  position: relative;
  height: 814px;
  width: 100%;
}

.talkme_conversations {
  position: relative;
  top: 160px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: start;
  width: 45%;
  left: 19%;
  gap: 20px;
}

.talkme_conversations span:first-of-type {
  font-family: LexendDeca-Bold;
  font-size: 52px;
}

.talkme_conversations span:last-of-type {
  font-family: LexendDeca-Regular;
  font-size: 18px;
  text-align: left;
}

.talkme_conversations_img {
  position: absolute;
  top: 160px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-end;
  width: 45%;
  left: 19%;
  gap: 20px;
  height: 693px;
}
</style>